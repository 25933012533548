import React, { useEffect, useState } from 'react';
import { Row, Col, Card, List } from 'antd';
import UserDetails from './UserDetails';
import DepartmentStatistics from './DepartmentStatistics';

const Dashboard = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const getOrganizationInfo = () => {
    const info = [];
    if (userData.organization) {
      info.push({ label: "Organization", value: userData.organization.name || 'N/A' });
    }
    if (userData.departments && userData.departments.length > 0) {
      info.push({ label: "Departments", value: userData.departments.map(dept => dept.name).join(", ") });
    }
    return info;
  };

  const cardHeaderStyle = {
    backgroundColor: '#001529',
    color: 'white'
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card 
            title="User Information" 
            style={{ height: '100%' }}
            headStyle={cardHeaderStyle}
          >
            <UserDetails userData={userData} />
          </Card>
        </Col>
        <Col span={12}>
          <Card 
            title="Organization Information" 
            style={{ height: '100%' }}
            headStyle={cardHeaderStyle}
          >
            <List
              size="small"
              dataSource={getOrganizationInfo()}
              renderItem={item => (
                <List.Item>
                  <strong>{item.label}:</strong> {item.value}
                </List.Item>
              )}
            />
          </Card>
        </Col>
        <Col span={24}>
          <DepartmentStatistics />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;