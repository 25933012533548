import React from 'react';
import { Layout, Menu, Button, Typography, theme } from 'antd';
import { DashboardOutlined, FileOutlined, BarChartOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import logo from './logo.png'; // Adjust this path if necessary to point to your logo file

const { Header, Content, Sider, Footer } = Layout;
const { Text } = Typography;

const AppLayout = ({ children, userData, onLogout }) => {
  const location = useLocation();
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

  const items = [
    {
      key: 'dashboard',
      icon: <DashboardOutlined />,
      label: <Link to="/dashboard">Dashboard</Link>,
    },
    {
      key: 'report-entries',
      icon: <FileOutlined />,
      label: <Link to="/report-entries">Reports</Link>,
    },
    {
      key: 'report-analytics',
      icon: <BarChartOutlined />,
      label: <Link to="/report-analytics">Analytics</Link>,
    },
    ...(userData.is_org_admin ? [
      {
        key: 'user-management',
        icon: <UserOutlined />,
        label: <Link to="/user-management">User Management</Link>,
      }
    ] : [])
  ];

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ 
        background: colorBgContainer, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        padding: '0 16px',
        boxShadow: '0 1px 4px rgba(0,21,41,.08)'
      }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src={logo} alt="Logo" style={{ height: '60px', marginRight: '10px' }} />
          <Text strong style={{ color: '#001529' }}>
            DEPARTMENT OF MEDICAL EDUCATION
            <br />
            Government Of Uttarakhand
          </Text>
        </div>
        <div>
          <Text style={{ marginRight: '20px' }}>Welcome, {userData.user.username}!</Text>
          <Button 
            type="link" 
            icon={<LogoutOutlined />} 
            onClick={onLogout}
            style={{ color: '#001529' }}
          >
            Logout
          </Button>
        </div>
      </Header>
      <Layout>
        <Sider
          width={200}
          style={{ background: '#001529' }}
          breakpoint="lg"
          collapsedWidth="0"
        >
          <Menu
            mode="inline"
            theme="dark"
            selectedKeys={[location.pathname.split('/')[1] || 'dashboard']}
            style={{ height: '100%', borderRight: 0 }}
            items={items}
          />
        </Sider>
        <Layout style={{ padding: '0 24px 24px' }}>
          <Content style={{ 
            padding: 24, 
            margin: '24px 0', 
            minHeight: 280, 
            background: colorBgContainer, 
            borderRadius: borderRadiusLG 
          }}>
            {children}
          </Content>
          <Footer style={{ textAlign: 'center', background: '#f0f2f5', padding: '12px 0' }}>
            Report Management System ©{new Date().getFullYear()} Created by Your Company
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AppLayout;