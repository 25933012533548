import React, { useState, useEffect, useCallback } from 'react';
import { Card, Row, Col, DatePicker, Empty, Spin, Statistic } from 'antd';
import {
  BarChartOutlined,
  TeamOutlined,
  RiseOutlined,
  FallOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { ResponsiveBar } from '@nivo/bar';

const { RangePicker } = DatePicker;

const DepartmentStatistics = () => {
  const [statistics, setStatistics] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchStatistics = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      let url = 'https://api-report-management-v3.uttarakhand.tech/api/department-statistics/';
      if (dateRange.length === 2) {
        const startDate = dateRange[0].format('YYYY-MM-DD');
        const endDate = dateRange[1].format('YYYY-MM-DD');
        url += `?start_date=${startDate}&end_date=${endDate}`;
      }
      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` }
      });
      setStatistics(response.data);
    } catch (error) {
      console.error('Failed to fetch department statistics:', error);
    } finally {
      setLoading(false);
    }
  }, [dateRange]);

  useEffect(() => {
    fetchStatistics();
  }, [fetchStatistics]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const getIcon = (value) => {
    if (value > 0) return <RiseOutlined style={{ color: '#3f8600' }} />;
    if (value < 0) return <FallOutlined style={{ color: '#cf1322' }} />;
    return <ExclamationCircleOutlined style={{ color: '#faad14' }} />;
  };

  const cardHeaderStyle = {
    backgroundColor: '#001529',
    color: 'white'
  };

  const renderChart = (data) => (
    <div style={{ height: 400 }}>
      <ResponsiveBar
        data={data}
        keys={['total_value']}
        indexBy="name"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: -45,
          legend: 'Entry Names',
          legendPosition: 'middle',
          legendOffset: 40
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Total Value',
          legendPosition: 'middle',
          legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }
            ]
          }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
      />
    </div>
  );

  return (
    <Card 
      title={<><BarChartOutlined /> Department Statistics</>}
      headStyle={cardHeaderStyle}
      extra={
        <RangePicker 
          onChange={handleDateRangeChange}
          style={{ marginBottom: 16 }}
        />
      }
    >
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : statistics.length > 0 ? (
        <Row gutter={[16, 16]}>
          {statistics.map((dept) => (
            <Col xs={24} sm={12} md={8} lg={6} key={dept.name}>
              <Card title={<><TeamOutlined /> {dept.name}</>}>
                {dept.entry_names.map((entry) => (
                  <Statistic
                    key={entry.name}
                    title={entry.name}
                    value={entry.total_value}
                    prefix={getIcon(entry.total_value)}
                    valueStyle={{ color: entry.total_value > 0 ? '#3f8600' : entry.total_value < 0 ? '#cf1322' : '#faad14' }}
                  />
                ))}
              </Card>
            </Col>
          ))}
          <Col span={24}>
            {renderChart(statistics.flatMap(dept => 
              dept.entry_names.map(entry => ({
                department: dept.name,
                name: entry.name,
                total_value: entry.total_value
              }))
            ))}
          </Col>
        </Row>
      ) : (
        <Empty description="No statistics available for the selected date range." />
      )}
    </Card>
  );
};

export default DepartmentStatistics;