import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, InputNumber, message } from 'antd';
import axios from 'axios';
import dayjs from 'dayjs';

const EditEntryForm = ({ visible, onCancel, onSuccess, entry }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible && entry) {
      form.setFieldsValue({
        department: entry.department.name,
        entry_name: entry.entry_name.name,
        value: entry.value,
        entry_date: dayjs(entry.entry_date).format('YYYY-MM-DD'),
      });
    }
  }, [visible, entry, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      
      const requestData = {
        value: values.value,
        entry_date: values.entry_date // Include the entry_date in the update request
      };

      console.log('Submitting data:', requestData);

      const response = await axios.put(
        `https://api-report-management-v3.uttarakhand.tech/api/update-report-entry/${entry.id}/`, 
        requestData,
        {
          headers: { 
            'Authorization': `Token ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      console.log('Backend response:', response.data);

      message.success('Entry updated successfully');
      onSuccess();
    } catch (error) {
      console.error('Error details:', error.response?.data);
      message.error('Failed to update entry: ' + (error.response?.data?.detail || 'Please try again.'));
    }
    setLoading(false);
  };

  return (
    <Modal
      visible={visible}
      title="Edit Entry"
      onCancel={onCancel}
      onOk={() => form.submit()}
      confirmLoading={loading}
      okText="Update"
    >
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item 
          name="department" 
          label="Department"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item 
          name="entry_name" 
          label="Entry Name"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item 
          name="value" 
          label={<><span style={{ color: 'red' }}>*</span> Value</>}
          rules={[{ required: true, message: 'Please input a value' }]}
        >
          <InputNumber placeholder="Value" style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item 
          name="entry_date" 
          label="Entry Date"
        >
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditEntryForm;