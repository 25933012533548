// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AppLayout from './components/AppLayout';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import ReportEntries from './components/ReportEntries';
import UserManagement from './components/UserManagement';
import ReportAnalytics from './components/ReportAnalytics'; // Add this import
import './custom-styles.css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const storedUserData = localStorage.getItem('userData');
    if (token && storedUserData) {
      setIsLoggedIn(true);
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    setIsLoggedIn(false);
    setUserData(null);
  };

  const handleLoginSuccess = (userData) => {
    setIsLoggedIn(true);
    setUserData(userData);
  };

  if (!isLoggedIn) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <Router>
      <AppLayout userData={userData} onLogout={handleLogout}>
        <Routes>
          <Route path="/dashboard" element={<Dashboard userData={userData} />} />
          <Route path="/report-entries" element={<ReportEntries />} />
          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/report-analytics" element={<ReportAnalytics />} /> {/* Add this line */}
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </AppLayout>
    </Router>
  );
}

export default App;