import React, { useState, useEffect, useCallback } from 'react';
import { Card, DatePicker, Spin, Alert, Empty, Typography, Row, Col, Statistic, Tag } from 'antd';
import { BarChartOutlined, CalendarOutlined, LineChartOutlined, RiseOutlined, FallOutlined } from '@ant-design/icons';
import axios from 'axios';
import { ResponsiveCalendar } from '@nivo/calendar';
import { ResponsiveLine } from '@nivo/line';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

const ReportAnalytics = () => {
  const [analyticsData, setAnalyticsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [allDates, setAllDates] = useState({ start: null, end: null });

  const fetchAnalyticsData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('token');
      let params = {};
      if (dateRange) {
        const [startDate, endDate] = dateRange;
        params = {
          start_date: startDate.format('YYYY-MM-DD'),
          end_date: endDate.format('YYYY-MM-DD'),
        };
      }
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/report-analytics/', {
        headers: { Authorization: `Token ${token}` },
        params: params,
      });
      setAnalyticsData(response.data);
      
      // Determine the overall date range from the data
      const allDates = response.data.flatMap(dept => dept.entries.map(entry => entry.date));
      const sortedDates = allDates.sort();
      setAllDates({
        start: sortedDates[0],
        end: sortedDates[sortedDates.length - 1]
      });
    } catch (error) {
      console.error('Failed to fetch analytics data:', error);
      setError(error.response?.data?.error || 'Failed to fetch analytics data. Please try again later.');
    }
    setLoading(false);
  }, [dateRange]);

  useEffect(() => {
    fetchAnalyticsData();
  }, [fetchAnalyticsData]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const formatDataForCalendar = (departmentData) => {
    return departmentData.entries.map(entry => ({
      day: entry.date,
      value: entry.total_value,
    }));
  };

  const formatDataForLineChart = (departmentData) => {
    const entryNames = [...new Set(departmentData.entries.map(entry => entry.entry_name))];
    return entryNames.map(name => ({
      id: name,
      data: departmentData.entries
        .filter(entry => entry.entry_name === name)
        .map(entry => ({
          x: entry.date,
          y: entry.total_value
        }))
    }));
  };

  const getOverallTrend = (data) => {
    if (data.length < 2) return 0;
    const sortedData = [...data].sort((a, b) => dayjs(a.day).diff(dayjs(b.day)));
    const firstValue = sortedData[0].value;
    const lastValue = sortedData[sortedData.length - 1].value;
    return lastValue - firstValue;
  };

  const renderDepartmentCard = (department) => {
    const calendarData = formatDataForCalendar(department);
    const lineChartData = formatDataForLineChart(department);
    const overallTrend = getOverallTrend(calendarData);

    return (
      <Card
        key={department.department_name}
        title={<Title level={4}><BarChartOutlined /> {department.department_name}</Title>}
        style={{ marginBottom: 16 }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Card title={<><CalendarOutlined /> Activity Heatmap</>} size="small">
              <div style={{ height: 200 }}>
                <ResponsiveCalendar
                  data={calendarData}
                  from={allDates.start}
                  to={allDates.end}
                  emptyColor="#eeeeee"
                  colors={['#61cdbb', '#97e3d5', '#e8c1a0', '#f47560']}
                  margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                  yearSpacing={40}
                  monthBorderColor="#ffffff"
                  dayBorderWidth={2}
                  dayBorderColor="#ffffff"
                />
              </div>
            </Card>
          </Col>
          <Col xs={24} md={12}>
            <Card title={<><LineChartOutlined /> Trend Analysis</>} size="small">
              <div style={{ height: 200 }}>
                <ResponsiveLine
                  data={lineChartData}
                  margin={{ top: 20, right: 20, bottom: 40, left: 40 }}
                  xScale={{ 
                    type: 'time', 
                    format: '%Y-%m-%d',
                    useUTC: false,
                    precision: 'day',
                  }}
                  xFormat="time:%Y-%m-%d"
                  yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                  axisBottom={{
                    format: '%b %d',
                    tickValues: 'every 30 days',
                    legend: 'Date',
                    legendOffset: 36,
                    legendPosition: 'middle'
                  }}
                  axisLeft={{
                    legend: 'Value',
                    legendOffset: -40,
                    legendPosition: 'middle'
                  }}
                  pointSize={8}
                  pointColor={{ theme: 'background' }}
                  pointBorderWidth={2}
                  pointBorderColor={{ from: 'serieColor' }}
                  useMesh={true}
                  legends={[
                    {
                      anchor: 'bottom-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 80,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                    }
                  ]}
                />
              </div>
            </Card>
          </Col>
          <Col xs={24}>
            <Statistic
              title="Overall Trend"
              value={overallTrend}
              precision={2}
              valueStyle={{ color: overallTrend >= 0 ? '#3f8600' : '#cf1322' }}
              prefix={overallTrend >= 0 ? <RiseOutlined /> : <FallOutlined />}
              suffix="units"
            />
          </Col>
        </Row>
      </Card>
    );
  };

  return (
    <div>
      <Title level={2}><BarChartOutlined /> Report Analytics</Title>
      <div style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}>
        <RangePicker
          onChange={handleDateRangeChange}
          style={{ marginRight: 16 }}
          value={dateRange}
        />
        {!dateRange && <Tag color="blue">Showing all-time data</Tag>}
      </div>
      {error && (
        <Alert 
          message="Error" 
          description={error}
          type="error" 
          showIcon 
          style={{ marginBottom: 16 }} 
        />
      )}
      {loading ? (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
        </div>
      ) : analyticsData.length > 0 ? (
        analyticsData.map(renderDepartmentCard)
      ) : (
        <Empty
          description={
            <Text>
              No data available. 
              {dateRange && ' Try selecting a different date range or check if there are any entries for this period.'}
            </Text>
          }
        />
      )}
    </div>
  );
};

export default ReportAnalytics;