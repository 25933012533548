import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Switch, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const CreateUser = ({ onSuccess }) => {
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/user-departments/', {
        headers: { Authorization: `Token ${token}` }
      });
      setDepartments(response.data);
    } catch (error) {
      message.error('Failed to fetch departments');
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://api-report-management-v3.uttarakhand.tech/api/org-admin/users/', values, {
        headers: { Authorization: `Token ${token}` }
      });
      message.success('User created successfully');
      form.resetFields();
      onSuccess();
    } catch (error) {
      message.error('Failed to create user: ' + (error.response?.data?.detail || 'Unknown error'));
    }
    setLoading(false);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item name="username" label="Username" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="first_name" label="First Name">
        <Input />
      </Form.Item>
      <Form.Item name="last_name" label="Last Name">
        <Input />
      </Form.Item>
      <Form.Item name="password" label="Password" rules={[{ required: true }]}>
        <Input.Password />
      </Form.Item>
      <Form.Item name="departments" label="Departments" rules={[{ required: true, type: 'array' }]}>
        <Select mode="multiple" placeholder="Select departments">
          {departments.map(dept => (
            <Option key={dept.id} value={dept.id}>{dept.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="is_dept_admin" label="Department Admin" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Create User
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CreateUser;