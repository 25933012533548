import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Space } from 'antd';
import axios from 'axios';
import CreateUser from './CreateUser';
import EditUser from './EditUser';

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/org-users/', {
        headers: { Authorization: `Token ${token}` }
      });
      setUsers(response.data);
    } catch (error) {
      message.error('Failed to fetch users');
    }
    setLoading(false);
  };

  const showCreateModal = () => {
    setIsCreateModalVisible(true);
  };

  const showEditModal = (user) => {
    setSelectedUser(user);
    setIsEditModalVisible(true);
  };

  const handleCreateSuccess = () => {
    setIsCreateModalVisible(false);
    fetchUsers();
  };

  const handleEditSuccess = () => {
    setIsEditModalVisible(false);
    fetchUsers();
  };

  const handleCancel = () => {
    setIsCreateModalVisible(false);
    setIsEditModalVisible(false);
    setSelectedUser(null);
  };

  const columns = [
    {
      title: 'Username',
      dataIndex: ['user', 'username'],
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      key: 'email',
    },
    {
      title: 'First Name',
      dataIndex: ['user', 'first_name'],
      key: 'first_name',
    },
    {
      title: 'Last Name',
      dataIndex: ['user', 'last_name'],
      key: 'last_name',
    },
    {
      title: 'Departments',
      dataIndex: 'departments',
      key: 'departments',
      render: (departments) => departments.map(dept => dept.name).join(', '),
    },
    {
      title: 'Is Dept Admin',
      dataIndex: 'is_dept_admin',
      key: 'is_dept_admin',
      render: (is_dept_admin) => is_dept_admin ? 'Yes' : 'No',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => showEditModal(record)}>Edit</Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2>User Management</h2>
      <Button type="primary" onClick={showCreateModal} style={{ marginBottom: 16 }}>
        Add New User
      </Button>
      <Table
        dataSource={users}
        columns={columns}
        rowKey={(record) => record.user.id}
        loading={loading}
      />
      <Modal
        title="Create New User"
        visible={isCreateModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateUser onSuccess={handleCreateSuccess} />
      </Modal>
      <Modal
        title="Edit User"
        visible={isEditModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        {selectedUser && <EditUser user={selectedUser} onSuccess={handleEditSuccess} />}
      </Modal>
    </div>
  );
};

export default UserManagement;