import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Button, message, Row, Col, DatePicker, Select, Card, Statistic, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import AddEntryForm from './AddEntryForm';
import EditEntryForm from './EditEntryForm';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title } = Typography;


const ReportEntries = () => {
  const [allEntries, setAllEntries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const [isOrgAdmin, setIsOrgAdmin] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [entryNames, setEntryNames] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEntryName, setSelectedEntryName] = useState(null);

  const fetchDepartments = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/user-departments/', {
        headers: { Authorization: `Token ${token}` }
      });
      setDepartments(response.data);
    } catch (error) {
      message.error('Failed to fetch departments');
    }
  }, []);

  const fetchEntryNames = useCallback(async (departmentId) => {
    if (!departmentId) {
      setEntryNames([]);
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://api-report-management-v3.uttarakhand.tech/api/entry-names/?department_id=${departmentId}`, {
        headers: { Authorization: `Token ${token}` }
      });
      setEntryNames(response.data);
    } catch (error) {
      message.error('Failed to fetch entry names');
    }
  }, []);

  const fetchEntries = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      let url = isOrgAdmin ? 'https://api-report-management-v3.uttarakhand.tech/api/org-report-entries/' : 'https://api-report-management-v3.uttarakhand.tech/api/user-report-entries/';
      let params = {};

      if (dateRange && dateRange.length === 2) {
        params.start_date = dateRange[0].format('YYYY-MM-DD');
        params.end_date = dateRange[1].format('YYYY-MM-DD');
      }

      const response = await axios.get(url, {
        headers: { Authorization: `Token ${token}` },
        params: params
      });
      setAllEntries(response.data);
    } catch (error) {
      message.error('Failed to fetch report entries');
    } finally {
      setLoading(false);
    }
  }, [dateRange, isOrgAdmin]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    setIsOrgAdmin(userData?.is_org_admin || false);
    fetchDepartments();
  }, [fetchDepartments]);

  useEffect(() => {
    fetchEntries();
  }, [fetchEntries]);

  useEffect(() => {
    if (selectedDepartment) {
      fetchEntryNames(selectedDepartment);
    } else {
      setEntryNames([]);
    }
    setSelectedEntryName(null);
  }, [selectedDepartment, fetchEntryNames]);

  const filteredEntries = useMemo(() => {
    return allEntries.filter(entry => {
      const departmentMatch = !selectedDepartment || entry.department.id === selectedDepartment;
      const entryNameMatch = !selectedEntryName || entry.entry_name.id === selectedEntryName;
      const dateMatch = !dateRange || (
        dayjs(entry.entry_date).isSameOrAfter(dateRange[0], 'day') &&
        dayjs(entry.entry_date).isSameOrBefore(dateRange[1], 'day')
      );
      return departmentMatch && entryNameMatch && dateMatch;
    });
  }, [allEntries, selectedDepartment, selectedEntryName, dateRange]);

  const entrySums = useMemo(() => {
    const sums = {};
    filteredEntries.forEach(entry => {
      const entryName = entry.entry_name.name;
      sums[entryName] = (sums[entryName] || 0) + entry.value;
    });
    return sums;
  }, [filteredEntries]);

  const formatUserName = (user) => {
    if (!user) return 'Unknown User';
    return [user.first_name, user.last_name].filter(Boolean).join(' ') || user.username;
  };

  const columns = [
    {
      title: 'Organization',
      dataIndex: ['organization', 'name'],
      key: 'organization',
    },
    {
      title: 'Department',
      dataIndex: ['department', 'name'],
      key: 'department',
    },
    {
      title: 'Entry Name',
      dataIndex: ['entry_name', 'name'],
      key: 'entry_name',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
    {
      title: 'Entry Date',
      dataIndex: 'entry_date',
      key: 'entry_date',
    },
    {
      title: 'Entered By',
      dataIndex: 'user',
      key: 'user',
      render: (user) => formatUserName(user),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button 
          type="default"
          onClick={() => handleEdit(record)}
        >
          Edit
        </Button>
      ),
    },
  ];

  const showAddModal = () => {
    setIsAddModalVisible(true);
  };

  const handleAddCancel = () => {
    setIsAddModalVisible(false);
  };

  const handleAddSuccess = () => {
    setIsAddModalVisible(false);
    fetchEntries();
  };

  const handleEdit = (record) => {
    setEditingEntry(record);
    setIsEditModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    setEditingEntry(null);
  };

  const handleEditSuccess = () => {
    setIsEditModalVisible(false);
    setEditingEntry(null);
    fetchEntries();
  };

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    setSelectedEntryName(null);
  };

  const handleEntryNameChange = (value) => {
    setSelectedEntryName(value);
  };

  return (
    <div>
      <Title level={2}>{isOrgAdmin ? 'All Department Report Entries' : 'Your Report Entries'}</Title>
      
      <Row gutter={[16, 16]} align="middle" justify="space-between" style={{ marginBottom: 16 }}>
        <Col>
          <Button 
            type="primary" 
            icon={<PlusOutlined />}
            onClick={showAddModal}
          >
            Add New Entry
          </Button>
        </Col>
        <Col>
          <Card size="small" style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              style={{ width: 200, marginRight: 8 }}
              placeholder="Select Department"
              onChange={handleDepartmentChange}
              value={selectedDepartment}
              allowClear
            >
              {departments.map(dept => (
                <Option key={dept.id} value={dept.id}>{dept.name}</Option>
              ))}
            </Select>
            <Select
              style={{ width: 200, marginRight: 8 }}
              placeholder="Select Entry Name"
              onChange={handleEntryNameChange}
              value={selectedEntryName}
              disabled={!selectedDepartment}
              allowClear
            >
              {entryNames.map(entry => (
                <Option key={entry.id} value={entry.id}>{entry.name}</Option>
              ))}
            </Select>
            <RangePicker 
              style={{ width: 300 }}
              onChange={handleDateRangeChange}
              value={dateRange}
            />
          </Card>
        </Col>
      </Row>

      <Card style={{ marginBottom: 16 }}>
        <Row gutter={[16, 16]}>
          {Object.entries(entrySums).map(([entryName, sum]) => (
            <Col span={6} key={entryName}>
              <Statistic title={entryName} value={sum} />
            </Col>
          ))}
        </Row>
      </Card>

      <Table 
        dataSource={filteredEntries} 
        columns={columns} 
        rowKey="id" 
        loading={loading}
      />
      
      <AddEntryForm
        visible={isAddModalVisible}
        onCancel={handleAddCancel}
        onSuccess={handleAddSuccess}
      />
      <EditEntryForm
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        onSuccess={handleEditSuccess}
        entry={editingEntry}
      />
    </div>
  );
};

export default ReportEntries;