// src/components/Login.js
import React, { useState } from 'react';
import { Form, Input, Button, Card, Typography } from 'antd';
import { UserOutlined, LockOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import logo from './logo.png'; // Adjust this path if necessary to point to your logo file
import axios from 'axios';

const { Text } = Typography;

const Login = ({ onLoginSuccess }) => {
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post('https://api-report-management-v3.uttarakhand.tech/api/token/', values);
      const token = response.data.token;
      localStorage.setItem('token', token);
      
      const profileResponse = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/user-profile/', {
        headers: { Authorization: `Token ${token}` }
      });
      
      const userData = profileResponse.data;
      localStorage.setItem('userData', JSON.stringify(userData));
      onLoginSuccess(userData);
    } catch (error) {
      console.error('Login failed:', error);
    }
    setLoading(false);
  };

  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh',
      background: '#f0f2f5'
    }}>
      <Card style={{ width: 600, borderRadius: 8, boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 24 }}>
          <img src={logo} alt="Logo" style={{ height: '60px', marginBottom: '10px' }} />
          <Text strong style={{ color: '#001529', textAlign: 'center' }}>
            DEPARTMENT OF MEDICAL EDUCATION
            <br />
            Government Of Uttarakhand
          </Text>
        </div>
        <Text type="secondary" style={{ display: 'block', textAlign: 'center', marginBottom: 24 }}>
          Welcome to Report Management System
        </Text>
        <Form
          name="normal_login"
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[{ required: true, message: 'Please input your Username!' }]}
          >
            <Input 
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} 
              placeholder="Username" 
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your Password!' }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
              size="large"
              iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%', height: 40}}>
              Log in
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Login;