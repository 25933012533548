// src/components/UserDetails.js
import React from 'react';
import { List } from 'antd';

const UserDetails = ({ userData }) => {
  const userInfo = [
    { label: "Username", value: userData.user.username || 'N/A' },
    { label: "Email", value: userData.user.email || 'N/A' },
  ];

  if (userData.is_org_admin) {
    userInfo.push({ label: "Organization Admin", value: "Yes" });
  }
  if (userData.is_dept_admin) {
    userInfo.push({ label: "Department Admin", value: "Yes" });
  }

  return (
    <List
      size="small"
      dataSource={userInfo}
      renderItem={item => (
        <List.Item>
          <strong>{item.label}:</strong> {item.value}
        </List.Item>
      )}
    />
  );
};

export default UserDetails;