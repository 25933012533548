import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Switch, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const EditUser = ({ user, onSuccess }) => {
  const [form] = Form.useForm();
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchDepartments();
    if (user) {
      form.setFieldsValue({
        username: user.user.username,
        email: user.user.email,
        first_name: user.user.first_name,
        last_name: user.user.last_name,
        departments: user.departments.map(dept => dept.id),
        is_dept_admin: user.is_dept_admin
      });
    }
  }, [user, form]);

  const fetchDepartments = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-report-management-v3.uttarakhand.tech/api/user-departments/', {
        headers: { Authorization: `Token ${token}` }
      });
      setDepartments(response.data);
    } catch (error) {
      message.error('Failed to fetch departments');
    }
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.patch(`https://api-report-management-v3.uttarakhand.tech/api/org-admin/users/${user.user.id}/`, values, {
        headers: { Authorization: `Token ${token}` }
      });
      message.success('User updated successfully');
      onSuccess();
    } catch (error) {
      message.error('Failed to update user: ' + (error.response?.data?.detail || 'Unknown error'));
    }
    setLoading(false);
  };

  return (
    <Form form={form} onFinish={onFinish} layout="vertical">
      <Form.Item name="username" label="Username" rules={[{ required: true }]}>
        <Input disabled />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={[{ required: true, type: 'email' }]}>
        <Input />
      </Form.Item>
      <Form.Item name="first_name" label="First Name">
        <Input />
      </Form.Item>
      <Form.Item name="last_name" label="Last Name">
        <Input />
      </Form.Item>
      <Form.Item name="departments" label="Departments" rules={[{ required: true, type: 'array' }]}>
        <Select mode="multiple" placeholder="Select departments">
          {departments.map(dept => (
            <Option key={dept.id} value={dept.id}>{dept.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="is_dept_admin" label="Department Admin" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={loading}>
          Update User
        </Button>
      </Form.Item>
    </Form>
  );
};

export default EditUser;